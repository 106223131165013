<template>
	<w-layout column fill-height>
		<w-flex shrink>
			<DocumentEditionPreferences />
		</w-flex>
		<w-flex shrink>
			<WebDavFolderPreferences />
		</w-flex>
	</w-layout>
</template>

<script>
import UserProfileModuleGuard from "@/mixins/ModulesGuards/UserProfile/UserProfileModuleGuard";

export default {
	name: 'PreferencesList',
	components: {
		DocumentEditionPreferences: () => ({
			component: import('@/components/Documents/Edition/DocumentEditionPreferences')
		}),
		WebDavFolderPreferences: () => ({
			component: import('@/components/Documents/Webdav/WebDavFolderPreferences')
		})
	},
	mixins: [UserProfileModuleGuard]
}
</script>
